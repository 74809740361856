import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import ReactHtmlParser from "html-react-parser"

const QaPage = ({ data }) => {
  const allQas = data.allStrapiQa.edges

  const filterQa = (qas, categoryWord) => {
    const result = qas.filter(x => {
      return x.node.category.name === categoryWord
    })
    return result
  }

  return (
    <Layout>
      <div className="qa-page design-style">
        <div className="contents">
          <div className="w-1060">
            {/* ページタイトル */}
            <div className="fw-b tx-center page-title">
              <h1 className="title e">Q&amp;A</h1>
              <p className="sub-title">よくあるご質問</p>
            </div>
            {/* /ページタイトル */}

            {/* カテゴリメニュー */}
            <div className="categories">
              <a href="#category-washing" className="left-column">お洗濯について</a>
              <a href="#category-item" className="center-column">セルフランドリーで洗えるもの</a>
              <a href="#category-machine" className="right-column">機器について</a>
              <a href="#baluko-app" className="left-column">Balukoアプリについて</a>
              <a href="#category-smart-app" className="center-column">スマートランドリーアプリについて</a>
              <a href="#category-receipt" className="right-column">領収書発行について</a>
              <a href="#category-card" className="left-column">バルコカードについて</a>
              <a href="#category-other" className="center-column">その他</a>
            </div>
            {/* /カテゴリメニュー */}

            <div className="qa-category-wrap">
              <h2 className="qa-category-title" id="category-washing">
                <p>お洗濯について</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "お洗濯について").map(({ node }) => (
                  <div className="qa-item" key={node.id}>
                    <input
                      type="checkbox"
                      id={node.id}
                      defaultChecked={false}
                      className="open-checkbox"
                    />
                    <label className="question" htmlFor={node.id}>
                      <span className="accordion">{node.question}</span>
                    </label>
                    <div className="answer">
                      {ReactHtmlParser(node.answer.data.answer)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="qa-category-footer"><a href="#page-content">カテゴリーメニュー</a></div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-title" id="category-item">
                <p>セルフランドリーで洗えるもの</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "セルフランドリーで洗えるもの").map(
                  ({ node }) => (
                    <div className="qa-item" key={node.id}>
                      <input
                        type="checkbox"
                        id={node.id}
                        defaultChecked={false}
                        className="open-checkbox"
                      />
                      <label className="question" htmlFor={node.id}>
                        <span className="accordion">{node.question}</span>
                      </label>
                      <div className="answer">
                        {ReactHtmlParser(node.answer.data.answer)}
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="qa-category-footer"><a href="#page-content">カテゴリーメニュー</a></div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-title" id="category-machine">
                <p>機器について</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "機器について").map(({ node }) => (
                  <div className="qa-item" key={node.id}>
                    <input
                      type="checkbox"
                      id={node.id}
                      defaultChecked={false}
                      className="open-checkbox"
                    />
                    <label className="question" htmlFor={node.id}>
                      <span className="accordion">{node.question}</span>
                    </label>
                    <div className="answer">
                      {ReactHtmlParser(node.answer.data.answer)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="qa-category-footer"><a href="#page-content">カテゴリーメニュー</a></div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-title" id="baluko-app">
                <p>Balukoアプリについて</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "アプリについて").map(({ node }) => (
                  <div className="qa-item" key={node.id}>
                    <input
                      type="checkbox"
                      id={node.id}
                      defaultChecked={false}
                      className="open-checkbox"
                    />
                    <label className="question" htmlFor={node.id}>
                      <span className="accordion">{node.question}</span>
                    </label>
                    <div className="answer">
                      {ReactHtmlParser(node.answer.data.answer)}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-tertiary-title">
                <p>Balukoアプリでの会員登録・ログインについて</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "会員登録・ログインについて").map(
                  ({ node }) => (
                    <div className="qa-item" key={node.id}>
                      <input
                        type="checkbox"
                        id={node.id}
                        defaultChecked={false}
                        className="open-checkbox"
                      />
                      <label className="question" htmlFor={node.id}>
                        <span className="accordion">{node.question}</span>
                      </label>
                      <div className="answer">
                        {ReactHtmlParser(node.answer.data.answer)}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-tertiary-title">
                <p>Balukoアプリでの機器稼働について</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "機器の稼働方法について").map(({ node }) => (
                  <div className="qa-item" key={node.id}>
                    <input
                      type="checkbox"
                      id={node.id}
                      defaultChecked={false}
                      className="open-checkbox"
                    />
                    <label className="question" htmlFor={node.id}>
                      <span className="accordion">{node.question}</span>
                    </label>
                    <div className="answer">
                      {ReactHtmlParser(node.answer.data.answer)}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-tertiary-title">
                <p>Balukoアプリでの決済について</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "決済について").map(({ node }) => (
                  <div className="qa-item" key={node.id}>
                    <input
                      type="checkbox"
                      id={node.id}
                      defaultChecked={false}
                      className="open-checkbox"
                    />
                    <label className="question" htmlFor={node.id}>
                      <span className="accordion">{node.question}</span>
                    </label>
                    <div className="answer">
                      {ReactHtmlParser(node.answer.data.answer)}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-tertiary-title">
                <p>Balukoアプリでのセール／クーポンについて</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "セール／クーポンについて").map(
                  ({ node }) => (
                    <div className="qa-item" key={node.id}>
                      <input
                        type="checkbox"
                        id={node.id}
                        defaultChecked={false}
                        className="open-checkbox"
                      />
                      <label className="question" htmlFor={node.id}>
                        <span className="accordion">{node.question}</span>
                      </label>
                      <div className="answer">
                        {ReactHtmlParser(node.answer.data.answer)}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-tertiary-title">
                <p>その他</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "その他（アプリ）").map(({ node }) => (
                  <div className="qa-item" key={node.id}>
                    <input
                      type="checkbox"
                      id={node.id}
                      defaultChecked={false}
                      className="open-checkbox"
                    />
                    <label className="question" htmlFor={node.id}>
                      <span className="accordion">{node.question}</span>
                    </label>
                    <div className="answer">
                      {ReactHtmlParser(node.answer.data.answer)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="qa-category-footer"><a href="#page-content">カテゴリーメニュー</a></div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-title" id="category-smart-app">
                <p>スマートランドリーアプリについて</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "スマートランドリーアプリについて").map(
                  ({ node }) => (
                    <div className="qa-item" key={node.id}>
                      <input
                        type="checkbox"
                        id={node.id}
                        defaultChecked={false}
                        className="open-checkbox"
                      />
                      <label className="question" htmlFor={node.id}>
                        <span className="accordion">{node.question}</span>
                      </label>
                      <div className="answer">
                        {ReactHtmlParser(node.answer.data.answer)}
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="qa-category-footer"><a href="#page-content">カテゴリーメニュー</a></div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-title" id="category-receipt">
                <p>領収書発行について</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "領収書発行について").map(({ node }) => (
                    <div className="qa-item" key={node.id}>
                      <input
                          type="checkbox"
                          id={node.id}
                          defaultChecked={false}
                          className="open-checkbox"
                      />
                      <label className="question" htmlFor={node.id}>
                        <span className="accordion">{node.question}</span>
                      </label>
                      <div className="answer">
                        {ReactHtmlParser(node.answer.data.answer)}
                      </div>
                    </div>
                ))}
              </div>
              <div className="qa-category-footer"><a href="#page-content">カテゴリーメニュー</a></div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-title" id="category-card">
                <p>バルコカードについて</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "バルコカードについて").map(({ node }) => (
                  <div className="qa-item" key={node.id}>
                    <input
                      type="checkbox"
                      id={node.id}
                      defaultChecked={false}
                      className="open-checkbox"
                    />
                    <label className="question" htmlFor={node.id}>
                      <span className="accordion">{node.question}</span>
                    </label>
                    <div className="answer">
                      {ReactHtmlParser(node.answer.data.answer)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="qa-category-footer"><a href="#page-content">カテゴリーメニュー</a></div>
            </div>

            <div className="qa-category-wrap">
              <h2 className="qa-category-title" id="category-other">
                <p>その他</p>
              </h2>
              <div className="qa-contents">
                {filterQa(allQas, "その他").map(({ node }) => (
                    <div className="qa-item" key={node.id}>
                      <input
                          type="checkbox"
                          id={node.id}
                          defaultChecked={false}
                          className="open-checkbox"
                      />
                      <label className="question" htmlFor={node.id}>
                        <span className="accordion">{node.question}</span>
                      </label>
                      <div className="answer">
                        {ReactHtmlParser(node.answer.data.answer)}
                      </div>
                    </div>
                ))}
              </div>
              <div className="qa-category-footer"><a href="#page-content">カテゴリーメニュー</a></div>
            </div>
          </div>
        </div>
      </div>
      {/*/.contents*/}
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="Q&A - よくあるご質問にお答えいたします。"
    description="機器の使い方や、バルコランドリープレイスのサービスについて、お客様のよくある疑問にお答えしております。"
  />
)
export default QaPage

export const query = graphql`
  query {
    allStrapiQa(
      sort: { fields: [sort_number], order: ASC }
      filter: {answer: {data: {answer: {ne: null}}}}
    ) {
      edges {
        node {
          id
          question
          category {
            name
          }
          answer {
            data {
              answer
            }
          }
        }
      }
    }
  }
`
